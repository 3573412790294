import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Modal, Spin } from 'antd'
import { endMeeting } from '../../../services/MeetingServices'
import TechnicianMeetingControls from './TechnicianMeetingControls'
import CustomerMeetingControls from './CustomerMeetingControls'
import { MAIN_APP_URL, removeLocalAndSessionStorage } from '../../../constants'
import mixpanel from 'mixpanel-browser'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CallEnd as CallEndIcon } from '@mui/icons-material'
import LeaveMeetingModal from '../../Common/LeaveMeetingModal'

const MeetingControllers = (props) => {
	const [disabledEndCall, setDisabledEndCall] = useState(false)
	const [isMobile, setIsMobile] = useState(false)
	// const [LeaveTheMeeting, setLeaveTheMeeting] = useState(false)
	// const [isTimerPaused, setIsTimerPaused] = useState(false)
	useEffect(() => {
		if (props.socket) {
			/**
			 * Socket will call when click on transfer button
			 * @params = socketData (Type: Object)
			 * @response : Will redirect customer to find technician screen
			 * @author : Karan
			 */
			props.socket.on('Meeting:transfered', async (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id) {
					if (props.userType === 'customer') {
						localStorage.removeItem('longJobPopUp')
						if (socketData.hireExpert) {
							window.location.href = `${MAIN_APP_URL}/customer/profile-setup?page=select-software&jobId=${socketData.jobId}&hireExpertTransferJob=true&transferredJob=true`
							// window.location.href = `${MAIN_APP_URL}/customer/profile-setup?jobId=${socketData.jobId}&hireExpertTransferJob=true&transferredJob=true`
						} else if (socketData.callStartType === 'Phone' && socketData.meetingStarted === true) {
							window.location.href = `${MAIN_APP_URL}/customer/profile-setup?page=select-software&jobId=${socketData.jobId}&newpost=No&transferredJob=true`
						} else {
							window.location.href = `${MAIN_APP_URL}/customer/profile-setup?page=select-software&jobId=${socketData.jobId}&newpost=No&transferredJob=true`
						}
					}
					if (props.userType === 'technician') {
						localStorage.removeItem('longJobPopUp')
						localStorage.removeItem('coBrandingLogoUrl')
						// window.location.href = `${MAIN_APP_URL}/`
						window.location.href = `${MAIN_APP_URL}/meeting-feedback/${socketData.jobId}`
					}
				}
			})

			/**
			 * Socket will redirect user to meeting feedback screen
			 * @params = socketData (Type: Object)
			 * @response : Will redirect user to meeting feedback screen
			 * @author : Karan
			 */
			props.socket.on('Meeting:over', async (socketData) => {
				if (socketData && socketData.jobId && props.job.id && socketData.jobId === props.job.id) {
					if (socketData.userType !== props.userType) {
						// window.location.href = `/meeting-over/${props.userType}/${props.job.id}`
						localStorage.removeItem('longJobPopUp')
						removeLocalAndSessionStorage(props.job.id)
						localStorage.removeItem('customerModalAction')
						localStorage.removeItem('customerResponceBackForTransfer')
						localStorage.removeItem('isBackdropVisible')
						localStorage.removeItem('coBrandingLogoUrl')
						// localStorage.removeItem('firstWarningMessageObject')
						localStorage.removeItem('firstMessageSent')
						window.location.href = `${MAIN_APP_URL}/meeting-feedback/${socketData.jobId}`
					}
				}
			})
		}
	}, [props.socket])

	useEffect(()=>{
		if(props.oneHourCompleted){
			endMeetingConfirm();
		}

	},[props.oneHourCompleted,props.oneHourPass])


	// useEffect(() => {
	// console.log('user  inside controllers >>>>>>', props.user)
	// }, [props.user])

	/**
	 * End meeting confirmation modal
	 * @params = ''
	 * @response : Will open the end meeting confirmation modal
	 * @author : Karan
	 */
	const endMeetingConfirm = () => {
		// mixpanel code
		mixpanel.identify(props.user.email)
		mixpanel.track(`${props.userType} - Click on end meeting button`, { JobId: props.job.id })
		// mixpanel code
		setDisabledEndCall(true)
		props.setJitsiSessionEnd(true)
		props.setShowEndMeetingSpinner(true)
		endMeeting(props.jitsiApi, props.job, props.user, props.socket)

		// Modal.confirm({
		// 	title: 'Are you sure you want to end the meeting?',
		// 	okText: 'Yes',
		// 	cancelText: 'No',
		// 	className: 'app-confirm-modal',
		// 	okButtonProps: {
		// 		id: 'confirm-end-meeting-btn', // Set the id for the "Yes" button
		// 	},
		// 	onOk() {
		// 		localStorage.removeItem('longJobPopUp')
		// 		sessionStorage.removeItem('reconnected')
		// 		localStorage.removeItem(`timerPaused-${props.job.id}`)

		// 		// mixpanel code
		// 		mixpanel.identify(props.user.email)
		// 		mixpanel.track(`${props.userType} - Click Yes to end meeting `, { JobId: props.job.id })
		// 		// mixpanel code
		// 		setDisabledEndCall(true)
		// 		props.setJitsiSessionEnd(true)
		// 		endMeeting(props.jitsiApi, props.job, props.user, props.socket)
		// 	},
		// 	onCancel() {
		// 		// mixpanel code
		// 		mixpanel.identify(props.user.email)
		// 		mixpanel.track(`${props.userType} - Click No to end meeting `, { JobId: props.job.id })
		// 		// mixpanel code
		// 	},
		// })
	}

	const goToDashboard = () => {
		localStorage.removeItem('longJobPopUp')
		if(props.userType === "technician"){
			window.location.href = `${MAIN_APP_URL}/dashboard/technician`
		}else{
			window.location.href = `${MAIN_APP_URL}`
		}
	}
	const leaveTooltip = (
		<Tooltip id="leave-tooltip">
			<div>
				'Leave Meeting'
				<br />
				allows you to rejoin at any time.
			</div>
		</Tooltip>
	)

	const handleMouseOver = () => {
		setIsMobile(true)
	}

	const handleMouseOut = () => {
		setIsMobile(false)
	}

	const handleLeaveClick = () => {
		goToDashboard()
	}

	const handleLeaveClickModal = () => {
		props.setLeaveTheMeeting(true)
	}

	return (
		<>
			<LeaveMeetingModal
				startTroubleShootModal={props.startTroubleShootModal}
				startswitchGeekModalModal={props.startswitchGeekModalModal}
				LeaveTheMeeting={props.LeaveTheMeeting}
				setLeaveTheMeeting={props.setLeaveTheMeeting}
				handleLeaveClick={handleLeaveClick}
				endMeetingConfirm={endMeetingConfirm}
			/>
			<Row>
				<Col md="6" className="end-meeting-btn-outer">
					{props.user && props.userType === 'technician' && (
						<>{props.job && props.job.technician && <TechnicianMeetingControls {...props} />}</>
					)}
					{props.user && props.userType === 'customer' && <>{props.job && props.job.customer && <CustomerMeetingControls {...props} />}</>}
				</Col>

				{props.showEndmeetingButton && (
					<Col className="end-meeting-btn-outer">
						<Button
							className="app-btn-red"
							id="automation-end-job-btn"
							onClick={handleLeaveClickModal}
							style={{ width: '11rem', height: '40px', color: 'white' }}
							variant="contained"
						>
							<CallEndIcon className="mr-" />
							&nbsp;&nbsp;
							<b>End Meeting</b>
						</Button>
					</Col>
				)}

				{props.showEndmeetingButton == false && (
					<Col md="6" className="end-meeting-btn-outer">
						<Button
							className={'app-btn app-btn-red'}
							onClick={() => {
								goToDashboard()
							}}
							title="Click this button to go to dashboard."
						>
							<span />
							Back to dashboard
						</Button>
					</Col>
				)}
			</Row>
		</>
	)
}

export default MeetingControllers
