import React, { useState, useRef, useEffect } from 'react'
import './twilio-chat-styles.css'
// import { useUser } from '../../context/useContext';
import geekerLogo from '../../assets/images/newChatLogo.png'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import * as TwilioChatApi from '../../api/twilioChatApi'
import * as JobApi from '../../api/job.api'
import { Col, notification, Spin } from 'antd'
import { Client as ConversationsClient } from '@twilio/conversations'
import Loader from '../../components/Common/Loader/index'
import { formatDateOfTwilioMessage, openNotificationWithIcon } from '../../utils'
import document from '../../assets/images/document.png'
import SendButton from '../../assets/images/paper-plane.png'
import EmptyChat from '../../assets/images/chat.png'
import { SERVER_URL } from '../../constants'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import notificationSound from '../../assets/sounds/notification.mp3'
import useSound from 'use-sound'
import UrlifyMessage from './UrlifyMessage'
import Alert from '@mui/material/Alert';
import { useVolume } from '../../context/VolumeProvider'

const ChatTextInput = ({ focusToInput, inputRef, disableChatButton, style, keyPress, mediaLoader }) => {
	if (focusToInput) {
		requestAnimationFrame(() => {
			inputRef.current.focus()
		})
	}

  return (
    <textarea
      ref={inputRef}
      type="text"
      placeholder="Type Here...."
      disabled={disableChatButton || mediaLoader}
      style={{ 
        ...style,
        resize: "none",
        maxHeight: "200px",
      }}
      onKeyPress={keyPress}
    >
    </textarea>
  );
}

const TwilioChatPanel = ({ width, height, job, socket, user, userType, setNotificationDot, socketHits, setSocketHits, startChat }) => {
	const chatContainerRef = useRef(null)
	const [messages, setMessages] = useState([])
	const fileInputRef = useRef(null)
	const [loadChat, setLoadChat] = useState(false)
	const [participantsList, setParticipantsList] = useState([])
	const [conversationProxy, setConversationProxy] = useState()
	const [chatStatus, setChatStatus] = useState({ statusString: '', status: '' })
	const [refreshSocket, setRefreshSocket] = useState(false)
	const [scrollToChat, setScrollToChat] = useState(false)
	const [prepareFileToSend, setPrepareFileToSend] = useState()
	const [mediaLoader, setMediaLoader] = useState(false)
	const [disableChatButton, setDisableChatButton] = useState(false)
	const [timeStampRefresh, setTimeStampRefresh] = useState(false)
	const [chatServiceSid, setChatServiceSid] = useState()
	const inputRef = useRef(null)
	const [shouldFocusInputRef, setShouldFocusInputRef] = useState(false)
	const [loadMoreChat, setLoadMoreChat] = useState(false)
	const [nextPageUrl, setNextPageUrl] = useState()
	const [copySuccess, setCopySuccess] = useState(false);
  const { effectiveVolume } = useVolume();
  const [play] = useSound(notificationSound, { volume: effectiveVolume })

	// This function will fetch the previous chats if available and then store to messages
	useEffect(() => {
		fetchPreviousChat()
	}, [])

	useEffect(() => {
		if (startChat != 'not-Set') {
			setShouldFocusInputRef(true)
		}
	}, [startChat])

	useEffect(() => {
		let timeoutId;
		if (copySuccess) {
		  timeoutId = setTimeout(() => {
			setCopySuccess(false);
		  }, 1000);
		}
		return () => {
		  clearTimeout(timeoutId);
		};
	  }, [copySuccess]);
	

	useEffect(() => {
		setInterval(() => {
			setTimeStampRefresh((prevValue) => !prevValue)
		}, 60000)
	}, [])

	useEffect(() => {
		if (job) {
			fetchChatOrCreate()
		}
	}, [])

	// This socket is used to notify another user to refesh twilio chat panel of another side
	useEffect(() => {
		socket.on('refresh-twilio-chat-panel-send', (data) => {
			if (data.job === job?.id) {
				setRefreshSocket(true)
				setTimeout(function () {
					setScrollToChat(true)
				}, 500)
			}
		})
	}, [socket])

	// This will auto-scroll the chat to the current chat portion
	useEffect(() => {
		if (scrollToChat) {
			if (chatContainerRef && chatContainerRef.current) {
				const chatContainer = chatContainerRef.current
				if (chatContainer) {
					chatContainer.scrollTop = chatContainer.scrollHeight
					setScrollToChat(false)
				}
			}
		}
	}, [scrollToChat, messages])

	const handleChatScroll = async (e) => {
		if (!loadMoreChat && e.target.scrollTop < 60) {
			setLoadMoreChat(true)
			if (nextPageUrl != null) {
				await fetchPreviousChatOnScroll()
			}
			setLoadMoreChat(false)
		}
	}

	const handleChatScrollButton = async (e) => {
		setLoadMoreChat(true)
		if (nextPageUrl != null) {
			await fetchPreviousChatOnScroll()
		}
		setLoadMoreChat(false)
	}

	/**
	 * @description : This function will firstly try to fetch conversation room if not available then we will create a new conversation
	 * @response : Returns chat pannel
	 * @author : kartar singh
	 */
	const fetchChatOrCreate = async () => {
		try {
			setLoadChat(true)
			let twilioData = {
				chatId: job ? job.id : null,
			}
			const responceChat = await TwilioChatApi.fetchTwilioConversation(twilioData)
			if (responceChat.twilioData.success) {
				const sid = responceChat?.twilioData?.conversation?.sid
				const chatServiceSid = responceChat?.twilioData?.conversation?.chatServiceSid
				setChatServiceSid(chatServiceSid)
				if (sid && chatServiceSid) {
					const addParticipat = await TwilioChatApi.addTwilioParticiants({
						conversationSid: sid,
						userDetails: user,
						chatServiceSid: chatServiceSid,
					})
					// We will only re-run fetch chat api if messages state is empty
					if (messages.length <= 0) {
						await fetchPreviousChat()
					}
					// Get Participant List by Id
					ChatParticiantsList(sid)
					// This will update user Joined Status which is important to join the chat
					userChatStatus(addParticipat?.twilioData?.token)
					setLoadChat(false)
				}
			}
			setLoadChat(false)
			setScrollToChat(false)
		} catch (err) {
			console.log('error in fetchChatOrCreate', err)
			setLoadChat(false)
			setScrollToChat(false)
		}
	}

	useEffect(() => {
		if (conversationProxy) {
			setTimeout(function () {
				setLoadChat(false)
				setScrollToChat(true)
			}, 1000)
		}
	}, [conversationProxy])

	// This will fetch chat of user  on the basis of chat id that is stored in database
	const fetchPreviousChat = async () => {
		if (job) {
			let dataToSend = { chat_id: job.id }
			const chatResponce = await TwilioChatApi.getTwilioChatDetails(dataToSend)
			console.log('chatResponce :::::::::')
			const twilioChatSid = chatResponce?.conversation[0].twilio_chat_service?.sid
			const twiliochatServiceId = chatResponce?.conversation[0]?.twilio_chat_service?.chatServiceSid

			if (chatResponce?.conversation?.length > 0 && twilioChatSid && twiliochatServiceId) {
				const chatDetails = await TwilioChatApi.getTwilioChat(twilioChatSid, twiliochatServiceId)
				if (chatDetails.formattedResponse.length > 0) {
					setMessages(chatDetails.formattedResponse)
					setNextPageUrl(chatDetails.nextPageUrl)
				}
			}
		}
	}

	const fetchPreviousChatOnScroll = async () => {
		// let chatId = await getChatIdForUser()
		// console.log("job.id)job.id)",job.id)
		let dataToSend = { chat_id: job.id }
		const chatResponce = await TwilioChatApi.getTwilioChatDetails(dataToSend)
		const twilioChatSid = chatResponce?.conversation[0].twilio_chat_service?.sid
		const twiliochatServiceId = chatResponce?.conversation[0]?.twilio_chat_service?.chatServiceSid

		if (chatResponce?.conversation?.length > 0 && twilioChatSid && twiliochatServiceId && nextPageUrl != null) {
			const chatDetails = await TwilioChatApi.getTwilioChat(twilioChatSid, twiliochatServiceId, nextPageUrl)

			if (chatDetails.formattedResponse.length > 0) {
				let previousMessages = [...messages]
				previousMessages.push(...chatDetails.formattedResponse)
				setNextPageUrl(chatDetails.nextPageUrl)
				setMessages(previousMessages)
			}
		}
	}

	// This will fetch participant list of particular conversation group by sid
	const ChatParticiantsList = async (sid) => {
		try {
			const particiantsList = await TwilioChatApi.twilioParticiantsList({ conversationSid: sid })
			console.log('particiantsList of Twilio Chat :::::', particiantsList)
			let particiantsAttribute = []
			particiantsList.twilioData.participants.forEach((particiant) => {
				if (JSON.parse(particiant.attributes).userId !== user.id) {
					particiantsAttribute.push(JSON.parse(particiant.attributes))
				}
			})
			setParticipantsList(particiantsAttribute)
		} catch (error) {
			console.log('error while fetching participantlist', error)
			return
		}
	}

	// This function is used to track the status of connction of conversation and help user to join the conversation
	const userChatStatus = (token) => {
		try {
			// Initializing Conversation Client
			const conversationsClient = new ConversationsClient(token)
			// Connecting to the Conversation and track conversation status
			conversationsClient.on('connectionStateChanged', (state) => {
				if (state === 'connecting')
					setChatStatus({
						statusString: 'Connecting to Chat ...',
						status: 'default',
					})
				if (state === 'connected') {
					setChatStatus({
						statusString: 'You are connected.',
						status: 'success',
					})
					const warningMessage = {
						senderName: "Geeker",
						text: "Reminder: Geeker's T&C requires that you share all information via internal chat and do not share contact information. If you need any assistance during a call/meeting, please chat with our support team. Good luck with your call!",
						timeStamp: new Date(),
						geekerMsg: true,
					};
					setMessages((prevMessages) => [warningMessage, ...prevMessages]);
				}
				if (state === 'denied')
					setChatStatus({
						statusString: 'Facing issue while connecting your chat. Please refresh the page and try again',
						status: 'error',
					})
			})
			// Join the conversation  on the basis of JobId
			conversationsClient.on('conversationJoined', (conversation) => {
				const friendlyName = conversation.friendlyName
				if (friendlyName == job?.id) {
					setConversationProxy(conversation)
					setConversationProxy(conversation)
				}
			})
		} catch (error) {
			setChatStatus({
				statusString: 'Facing issue while connecting your chat. Please refresh the page and try again',
				status: 'error',
			})
		}
	}

	useEffect(() => {
		if (conversationProxy) {
			let updatedMessages = [...messages]
			conversationProxy.on('messageAdded', async (data) => {
				let lastReadMessageIndex = data?.state?.index
				let imageUrl =
					data.state.body === 'file has been uploaded' && data.state.media ? await data.state.media.getContentTemporaryUrl() : false
				let mediaDetails =
					data.state.body === 'file has been uploaded' && data.state.media
						? { chatServiceSid: chatServiceSid, mediaSid: data.state.media?.state?.sid }
						: {}
				let content_type = data.state.body === 'file has been uploaded' && data.state.media ? data.state.media.contentType : false
				updatedMessages = [
					{
						senderName: data.state.attributes.userName,
						text: data.state.body,
						author: data.state.author,
						timeStamp: data.state.attributes.timeStamp,
						imageUrl: imageUrl,
						content_type: content_type,
						mediaDetails: mediaDetails,
					},
					...updatedMessages,
				]
				setMediaLoader(false)
				setMessages(updatedMessages)
				conversationProxy.getParticipants().then((particiant) => {
					if (particiant.length > 0) {
						if (data.state.author == user?.id) {
							conversationProxy.updateLastReadMessageIndex(lastReadMessageIndex)
						}
					}
				})
			})
			setRefreshSocket(false)
		}
	}, [conversationProxy])

	useEffect(() => {
		if (conversationProxy) {
			conversationProxy.on('messageAdded', async (data) => {
				// this will set message as read in Chat  when user receieve new message
				let lastReadMessageIndex = data?.state?.index
				const author = data?.state?.author
				if (author == user?.id) {
					conversationProxy.updateLastReadMessageIndex(lastReadMessageIndex)
				}
				if (data?.state?.author != user?.id) {
					setSocketHits((prevHits) => prevHits + 1)
					play()
					notification.destroy()
					notification.info({ key: 'newMessageReceived', duration: 4, message: `New chat message received` })
					setNotificationDot(true)
				}
			})
		}
	}, [conversationProxy])

	const copyToClipboard = async (messageTOCopy) => {
		try {
		  //console.log('messageTOCopy : :: ::', messageTOCopy)
		  const linkRegex = /(https?:\/\/[^\s]+)/g; // Regular expression to match URLs
		  const linkMatch = messageTOCopy.match(linkRegex);
		  if (linkMatch && linkMatch.length > 0) {
			const linkToCopy = linkMatch[0]; // Assuming the first link found is the one to copy
			await navigator.clipboard.writeText(linkToCopy);
			setCopySuccess(true);
		  } else {
			setCopySuccess(false); // No link found to copy
		  }
		} catch (err) {
		  console.error('Unable to copy text to clipboard:', err);
		  setCopySuccess(false);
		}
	  };

	// This function is used to send message
	const handleSendMessage = async () => {
		let textMessage = inputRef.current.value
		console.log('textMessage :::::')
		if (textMessage.length > 1000) {
			setDisableChatButton(false)
			openNotificationWithIcon('error', 'Error', 'Characters limit exceeds !')
			return
		}

		if (conversationProxy && textMessage.trim().length > 0 && !prepareFileToSend) {
			setDisableChatButton(true)
			const attribues = {
				timeStamp: new Date(),
				userName: user?.firstName + ' ' + user?.lastName,
				email: user?.email,
			}
			conversationProxy.sendMessage(textMessage, attribues).then((message) => {
				// console.log('messasge to twilio ::::', message)
				inputRef.current.value = ''
			})
			setScrollToChat(true)
			if (user.userType === 'customer') {
				socket.emit('talk-js-notification', job.id)
			} else {
				socket.emit('talk-js-notification-to-customer', job.id)
			}

			socket.emit('refresh-twilio-chat-panel', { job: job.id })
			setTimeout(function () {
				setScrollToChat(true)
				setDisableChatButton(false)
			}, 500)
		} else if (conversationProxy && prepareFileToSend) {
			// console.log('prepareFileToSend ::: ', prepareFileToSend)
			setMediaLoader(true)

			const attribues = {
				timeStamp: new Date(),
				userName: user?.firstName + ' ' + user?.lastName,
				email: user?.email,
			}

			await conversationProxy
				.prepareMessage()
				.setBody('file has been uploaded')
				.addMedia(prepareFileToSend)
				.setAttributes(attribues)

				.build()
				.send()

			inputRef.current.value = ''
			setPrepareFileToSend(null)
			// this will reset the file and with this one can upload same file in the chat
			fileInputRef.current.value = null
			// this will reset the file and with this one can upload same file in the chat
			fileInputRef.current.value = null
			if (user.userType === 'customer') {
				socket.emit('talk-js-notification', job.id)
			} else {
				socket.emit('talk-js-notification-to-customer', job.id)
			}
			// here we are refetchning the chat as we want to show the names as well of uploaded docs
			fetchPreviousChat()
		}
		setShouldFocusInputRef(true)
	}
	const containerStyle = {
		width: width,
		height: height,
		border: '1px solid #ccc',
		backgroundColor: '#fff',
		boxShadow: `rgb(136, 136, 136) 1px 1px 3px`,
	}

	const handleFileInputChange = async (event) => {
		setScrollToChat(true)
		const selectedFile = event.target.files[0]

		if (selectedFile.size > 15 * 1024 * 1024) {
			// File size exceeds 15MB
			openNotificationWithIcon('error', 'Error', 'File size cannot exceed 15 MB.')
			return
		}
		// console.log('selectedFile:', selectedFile);
		var formdata = new FormData()
		formdata.append('file', selectedFile)
		setPrepareFileToSend(formdata)
		inputRef.current.value = selectedFile.name
	}

	const handleIconClick = () => {
		fileInputRef.current.click()
	}

	if (loadChat) {
		return (
			<div className="loader-name-style" style={{ ...containerStyle }}>
				<h5 className="d-flex flex-column mb-3">{chatStatus.statusString ? chatStatus.statusString : 'Loading Chat'}</h5>
				<Spin style={{ fontSize: '20px' }} />
			</div>
		)
	}
	return (
		<>
			<div className="d-flex flex-column" style={{ ...containerStyle }}>
				<div className="chat-header d-flex align-items-center pl-3">
					<img src={geekerLogo} className="geek-icon-style" alt="bellIcon" />
					<div className="d-flex flex-column">
						<div className="d-flex flex-wrap row names-style">
							{participantsList.length > 0 &&
								participantsList.map((item, index) => {
									return item.userType && item.userType != 'SuperAdmin' ? (
										<span className="chatheader" style={{ lineHeight: '11px' }} key={index}>
											<b>
												{item.name}
												{index != participantsList.length - 1 ? '  ' : ''}
											</b>
										</span>
									) : (
										''
									)
								})}
						</div>
						<span style={{ fontSize: '11px' }}>{job?.software?.name}</span>
					</div>
				</div>
				{copySuccess &&
					<Alert severity="success">link has been copied.</Alert>
				}
				<div
					id="scrollToChat"
					onScroll={handleChatScroll}
					ref={chatContainerRef}
					className="chat-display-box chat-container justify-content-end"
					style={{ height: `calc(${height} - 110px)`, overflowY: 'scroll' }}
				>
					{loadMoreChat && (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)}
					{nextPageUrl && (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button onClick={handleChatScrollButton}>load more</Button>
						</Box>
					)}
					{messages && messages.length > 0 ? (
						<div className="d-flex flex-column-reverse justify-content-start" style={{ height: 'auto', minHeight: '100%' }}>
							{messages.map((message, index) =>
								message.author === user.id ? (
									<div key={index} className="message-div d-flex flex-column align-self-end align-items-end">
										<div style={{ margin: 0, display: 'flex', justifyContent: 'end' }}>
											<h6 className="chat-customer-name">{message?.senderName}</h6>
										</div>
										{message.imageUrl ? (
											<a
												href={`${SERVER_URL}/twilio-chat/get-media-link-updated?chatServiceSid=${message?.mediaDetails?.chatServiceSid}&mediaSid=${message?.mediaDetails?.mediaSid}`}
												target="_blank"
												className="chat-image-style"
											>
												{message.imageUrl && message.content_type.includes('image/') ? (
													<img src={message.imageUrl} style={{ maxHeight: '80px' }} />
												) : (
													<img src={document} style={{ maxHeight: '80px' }} />
												)}
												<p className="media-name-style">{message.mediaName}</p>
											</a>
										) : (
											<div
											className={`me-chat-div`}
											style={{
											  display: "flex",
											  justifyContent: "flex-end",
											  flexDirection: "column",
											}}
										  >
											<UrlifyMessage
											  message={message.text}
											  copyToClipboard={copyToClipboard}
											  formatDateOfTwilioMessage={formatDateOfTwilioMessage}
											  timeStamp={message.timeStamp}
												geekerMsg={message.geekerMsg ? message.geekerMsg : false}
											/>
										  </div>
										)}
									</div>
								) : (
									<div key={index} className="message-div d-flex flex-column">
										<h6 className="chat-customer-name">{message?.senderName}</h6>
										{message.imageUrl ? (
											<a
												href={`${SERVER_URL}/twilio-chat/get-media-link-updated?chatServiceSid=${message?.mediaDetails?.chatServiceSid}&mediaSid=${message?.mediaDetails?.mediaSid}`}
												target="_blank"
											>
												{message.imageUrl && message.content_type.includes('image/') ? (
													<img src={message.imageUrl} style={{ maxHeight: '80px' }} />
												) : (
													<img src={document} style={{ maxHeight: '80px' }} />
												)}
												<p className="media-name-style">{message.mediaName}</p>
											</a>
										) : (
											<div
											className={`me-chat-div`}
											style={{
											  display: "flex",
											  justifyContent: "flex-end",
											  flexDirection: "column",
											}}
										  >
											<UrlifyMessage
											  message={message.text}
											  copyToClipboard={copyToClipboard}
											  formatDateOfTwilioMessage={formatDateOfTwilioMessage}
											  timeStamp={message.timeStamp}
												geekerMsg={message.geekerMsg ? message.geekerMsg : false}
											/>
										  </div>
										)}
									</div>
								),
							)}

							{mediaLoader && (
								<div className="d-flex align-self-end " style={{ marginRight: '20px' }}>
									<Loader height="50%" />
								</div>
							)}
						</div>
					) : (
						<NochatAvailableDesign userType={userType} />
					)}
				</div>

				<div className="chat-input-box d-flex align-items-center">
					<ChatTextInput
						inputRef={inputRef}
						disableChatButton={disableChatButton}
						mediaLoader={mediaLoader}
						focusToInput={shouldFocusInputRef}
						style={{
							width: '90%',
							height: '100%',
							padding: '10px',
							border: 'none',
							outline: 'none',
						}}
						keyPress={(e) => {
							if (e.key === 'Enter') {
								handleSendMessage()
							}
						}}
					/>

					<input
						type="file"
						id="attach-file"
						disabled={mediaLoader}
						style={{ display: 'none' }}
						onChange={handleFileInputChange}
						ref={fileInputRef}
					/>
					<IconButton
						aria-label="attach-file"
						component="span"
						onClick={handleIconClick}
						disabled={mediaLoader}
						style={{ transform: 'rotate(45deg)', marginRight: '10px' }}
					>
						<AttachFileIcon />
					</IconButton>

					<IconButton
						style={{ backgroundColor: '#1bd4d5', color: 'white', marginRight: '15px' }}
						onClick={handleSendMessage}
						disabled={disableChatButton || mediaLoader}
					>
						<SendIcon />
					</IconButton>
				</div>
			</div>
		</>
	)
}

const NochatAvailableDesign = ({ userType }) => {
	return (
		<div className="d-flex align-items-center justify-content-center flex-column" style={{ height: '100%' }}>
			<img src={EmptyChat} className="no-chat-available-css" />
			<p className="empty-chat-message">
				{userType == 'customer' ? 'Connect with geeker  for assistance.' : 'Connect with customer for assistance.'}{' '}
			</p>
		</div>
	)
}

export default React.memo(TwilioChatPanel)
