import React from 'react'
import activeDashboardImg from '../../assets/images/active_tech.png'
import acceptJobImg from '../../assets/images/tech_accepts_job.png'
import startCallImg from '../../assets/images/start_call.png'
import joinMeetingImg from '../../assets/images/join_btn_customer.png'
import computerAudioImg from '../../assets/images/computer_audio.png'
import shareScreenImg from '../../assets/images/howto_share_screen.png'
import enableRemoteImg from '../../assets/images/howto_remote.png'
import transferJobImg from '../../assets/images/transfer_job.png'
import convertToProjectImg from '../../assets/images/howto_longjob.png'
import customerCommunicationImg from '../../assets/images/howto_converse.png'

const cardStyle = {
	border: '1px solid #e0e0e0',
	borderRadius: '8px',
	marginBottom: '20px',
	padding: '16px',
	boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}

const headerStyle = {
	fontSize: '18px',
	fontWeight: 'bold',
	marginBottom: '12px',
}

const imageStyle = {
	maxWidth: '100%',
	height: 'auto',
	marginTop: '16px',
	marginBottom: '16px',
}

const TutorialStep = ({ title, content, imageSrc, imageAlt }) => (
	<div style={cardStyle}>
		<h3 style={headerStyle}>{title}</h3>
		<p>{content}</p>
		{imageSrc && (
			<div style={{ textAlign: 'center' }}>
				<img src={imageSrc} alt={imageAlt} style={imageStyle} />
			</div>
		)}
	</div>
)

const TechTutorial = () => {
	const steps = [
		{
			title: 'Activate Dashboard',
			content: 'Make yourself active on the dashboard to begin receiving jobs.',
			imageSrc: activeDashboardImg,
			imageAlt: 'Active Tech Dashboard',
		},
		{
			title: 'Accept Job',
			content: "Click 'Accept Job' to be connected. The system will wait for the customer to start the call.",
			imageSrc: acceptJobImg,
			imageAlt: 'Tech Accepts Job',
		},
		{
			title: 'Start Call',
			content: "If the customer doesn't connect within 1-2 minutes, click the 'Start Call' button to call their cell phone.",
			imageSrc: startCallImg,
			imageAlt: 'Start Call Button',
		},
		{
			title: 'Customer Joins Meeting',
			content: "Instruct the customer to click 'JOIN' on their screen to join the meeting.",
			imageSrc: joinMeetingImg,
			imageAlt: 'Join Button for Customer',
		},
		{
			title: 'Switch to Computer Audio',
			content: "Ask the customer to click 'Computer Audio' to change from phone audio.",
			imageSrc: computerAudioImg,
			imageAlt: 'Computer Audio Option',
		},
		{
			title: 'Share Screen',
			content: "To share screen, have the customer click the 'Share' button on their screen.",
			imageSrc: shareScreenImg,
			imageAlt: 'Share Screen Button',
		},
		{
			title: 'Enable Remote Control',
			content: "For remote control, click 'Enable Remote'.",
			imageSrc: enableRemoteImg,
			imageAlt: 'Enable Remote Button',
		},
	]

	return (
		<div style={{ padding: '24px' }}>
			<h2 style={{ fontSize: '24px', marginBottom: '24px' }}>Tech Tutorial</h2>
			{steps.map((step, index) => (
				<TutorialStep key={index} {...step} />
			))}
			<div style={cardStyle}>
				<h3 style={headerStyle}>Additional Information</h3>
				<ul style={{ paddingLeft: '20px' }}>
					<li>
						<strong>Transfers:</strong> If you can't fix the issue or a different skill is needed, use the transfer option.
						<div style={{ textAlign: 'center', marginTop: '12px' }}>
							<img src={transferJobImg} alt="Transfer Job" style={imageStyle} />
						</div>
					</li>
					<li>
						<strong>Projects:</strong> For longer jobs, click 'Convert to Project' and select the appropriate option.
						<div style={{ textAlign: 'center', marginTop: '12px' }}>
							<img src={convertToProjectImg} alt="Convert to Project" style={imageStyle} />
						</div>
					</li>
					<li>
						<strong>Customer Communication:</strong> Use the Conversations tab under job Details for all customer communication. Do not
						send emails.
						<div style={{ textAlign: 'center', marginTop: '12px' }}>
							<img src={customerCommunicationImg} alt="Customer Communication" style={imageStyle} />
						</div>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default TechTutorial
