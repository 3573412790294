import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { subScriptionTimerDifference } from '../../../utils'
import * as CustomerApi from '../../../api/customer.api'
import notificationSound from '../../../assets/sounds/notification.mp3'
import useSound from 'use-sound'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { getTotalPaidJobs, updateJob, } from '../../../api/meeting.api'
import {FIRST_JOBPOP} from '../../../constants'
import { useVolume } from '../../../context/VolumeProvider'

const MeetingInfo = (props) => {
	const totalPaidJobs = props.totalPaidJobs
	const haveSubscription = props.haveSubscription
	const [chargeFromCard, setChargeFromCard] = useState(false)
	const { effectiveVolume } = useVolume();
  const [play] = useSound(notificationSound, { volume: effectiveVolume })
	const [userIsOwner, setUserIsOwner] = useState(true)
	const [ownerHaveSubscription, setOwnerHaveSubscription] = useState(false)
	const [ownerInfo, setOwnerInfo] = useState()
	const [addScroll, setAddScroll] = useState(false)
	const [addScrollTech, setAddScrollTech] = useState(false)
	const [isHovering, setIsHovering] = useState(false)
	const partnerUser = localStorage.getItem('coBrandingLogoUrl')
	const [partnerFirstJob, setPartnerFirstJob] = useState(false)
  const { job: meetingInfo, user: userInfo } = props;
  const [customerMeetingOS, setCustomerMeetingOS] = useState('unknown');

  const getOS = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) return 'Android';
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'iOS';
    if (/Win/i.test(userAgent)) return 'Windows';
    if (/Mac/i.test(userAgent)) return 'macOS';
    if (/Linux/i.test(userAgent)) return 'Linux';
    return 'unknown';
  }, []);

  useEffect(() => {
    const detectAndUpdateOS = async () => {
      if (userInfo?.userType === 'customer') {
        const customerOS = getOS();
        if (customerOS !== 'unknown' && customerOS !== meetingInfo?.meetingCustomerOS) {
          setCustomerMeetingOS(customerOS);
          try {
            await updateJob(meetingInfo?.id, {
              dataToUpdate: { meetingCustomerOS: customerOS },
              cuId: userInfo?.id,
            });
          } catch (error) {
            console.error('Failed to update customer OS:', error);
          }
        }
      } else if (userInfo?.userType === 'technician') {
        setCustomerMeetingOS(meetingInfo?.meetingCustomerOS || 'unknown');
      }
    };

		detectAndUpdateOS();

  }, [meetingInfo, userInfo, getOS]);

	useEffect(() => {
		;(async () => {
			if (userInfo && userInfo.ownerId && userInfo.ownerId !== null) {
				setUserIsOwner(false)
				const ownerInfoObject = await CustomerApi.getCustomer(userInfo.ownerId, props?.user?.id)
				// console.log('My console to look for ownerInfoObject 2', ownerInfoObject)
				if (ownerInfoObject) {
					setOwnerInfo(ownerInfoObject)
					if (ownerInfoObject && ownerInfoObject.subscription) {
						setOwnerHaveSubscription(true)
					}
				}
			}
		})()
	}, [props.user])

	useEffect(() => {
		; (async () => {
			
			if(partnerUser){
				const totalPaidJobsPartner = await getTotalPaidJobs(props?.job?.id, props?.job?.customer?.id, props?.user.id)
				
				if(totalPaidJobsPartner?.data?.totalPaidJobs==FIRST_JOBPOP){
					setPartnerFirstJob(true)
				}
			}
			
		})()
	},[partnerUser,props?.job])
	


	useEffect(() => {
		try {
			;(async function () {
				if (!props?.job?.add_card_timer_starts_at && props?.job?.meeting_start_time && props.userType == 'customer') {
					let subscriptionTimeLeft = await subScriptionTimerDifference(props?.job, props.user)
					subscriptionTimeLeft = subscriptionTimeLeft <= 0 ? 1 : parseInt(subscriptionTimeLeft)
					console.log('subScriptionTimerDifference 0')
					setTimeout(() => {
						setChargeFromCard(true)
					}, subscriptionTimeLeft * 1000)
				}
				if (props?.job?.add_card_timer_starts_at) props.setIsModalOpen(true)
			})()
		} catch (error) {
			console.log('Error occured in MeetingInfo -> useEffect[meetingInfo] : ', { error: error, jobId: meetingInfo.id })
		}
	}, [meetingInfo])

	const handelIssueDec = () => {
		setAddScroll(true)
	}

	const handelIssueDecTech = () => {
		setAddScrollTech(true)
	}
	const handleMouseEnter = () => {
		setIsHovering(true)
	}

	const handleMouseLeave = () => {
		setIsHovering(false)
	}
	return (
		<Row>
			<Col xs="12" className="pt-3">
				<Row>
					<Col md="5">
						{meetingInfo && meetingInfo.customer && (
							<>
								<p>
									<span className="meeting-label-name">Software:</span>
									<span>
										{meetingInfo.software.name + (meetingInfo.subSoftware ? ' (' + meetingInfo.subSoftware.name + ')' : '')}
									</span>
								</p>
							</>
						)}
						{userInfo?.userType === 'technician' && (
							<>
								<p>
									<span className="meeting-label-name">Customer OS:</span>
									<span>
										{customerMeetingOS}
									</span>
								</p>
							</>
						)}
						{meetingInfo && userInfo && userInfo.userType === 'customer' && (
							<>
								<span className="meeting-label-name">Technician:</span>
								<span className="meeting-label-value">
									{meetingInfo.technician.user.firstName} {meetingInfo.technician.user.lastName}
								</span>
							</>
						)}
						{meetingInfo && userInfo && userInfo.userType === 'technician' && (
							<>
								<span className="meeting-label-name">Customer:</span>
								<span className="meeting-label-value">
									{meetingInfo.customer.user.firstName} {meetingInfo.customer.user.lastName}
								</span>
							</>
						)}
					</Col>
					<Col md="3">
						{meetingInfo && meetingInfo.estimatedTime && !meetingInfo.is_long_job ? (
							<>
								<span className="meeting-label-name">Est. time:</span>
								<span className="meeting-label-value">{meetingInfo.estimatedTime} mins</span>
							</>
						) : (
							<></>
						)}
					</Col>
					<Col md="4">
						{props.user?.userType === 'customer' && meetingInfo && meetingInfo.estimatedPrice && !meetingInfo.is_long_job ? (
							<>
								{(userInfo?.userType === 'customer' || meetingInfo?.technician?.tag !== 'employed') && (
									<>
										{' '}
										{partnerFirstJob ? (
											<>
												<span className="meeting-label-name">Est. Price:</span>
												<div className="d-flex">
													<span className="meeting-label-value">
														<span>$</span>
														<strike>
															{meetingInfo.estimatedPrice.split('-')[0]}-<span> $</span>
															{meetingInfo.estimatedPrice.split('-')[1].trim()}
														</strike>
													</span>
													<span style={{ marginLeft: '5px' }}> Free job</span>
													<span
														className="estimate-info-round-div ml-1 position-relative"
														onMouseOver={handleMouseEnter}
														onMouseOut={handleMouseLeave}
														role="button"
														onTouchStart={handleMouseEnter}
														onFocus={() => setIsHovering(true)}
														onBlur={() => setIsHovering(false)}
													>
														<FontAwesomeIcon icon={faInfo} className="icon-of-i" />
														{isHovering && (
															<div className="estimatePriceInfo-div">
																<div className="phoneNumberInfo-triangle"></div>
																<div className="estimatePriceInfo-inner-div ">
																	<h6>Actual cost may be more or less.</h6>
																	<p className="estimate-price">
																		This estimate is to give you an idea of cost based on what other customers
																		paid for jobs in your category. Your actual job cost is charged per minute
																		while you're in the call on a "pay-as-you-go" basis depending on your category
																		pay rate. (Software = $1.99/minute, IT = $2.49/minute).
																	</p>
																</div>
															</div>
														)}
													</span>
												</div>
											</>
										) : (
											<>
												{userIsOwner || !ownerHaveSubscription ? (
													<>
														{totalPaidJobs === 0 && userInfo && !haveSubscription && !props.user?.ownerId ? (
															<>
																<span className="meeting-label-name">Est. Price:</span>
																<div className="d-flex">
																	<span className="meeting-label-value">
																		<span>$</span>
																		{meetingInfo.estimatedPrice.split('-')[0]}-<span> $</span>
																		{meetingInfo.estimatedPrice.split('-')[1].trim()}
																	</span>
																	<span>(First 6 minutes free)</span>
																	<span
																		className="estimate-info-round-div ml-1 position-relative"
																		onMouseOver={handleMouseEnter}
																		onMouseOut={handleMouseLeave}
																		role="button"
																		onTouchStart={handleMouseEnter}
																		onFocus={() => setIsHovering(true)}
																		onBlur={() => setIsHovering(false)}
																	>
																		<FontAwesomeIcon icon={faInfo} className="icon-of-i" />
																		{isHovering && (
																			<div className="estimatePriceInfo-div">
																				<div className="phoneNumberInfo-triangle"></div>
																				<div className="estimatePriceInfo-inner-div ">
																					<h6>Actual cost may be more or less.</h6>
																					<p className="estimate-price">
																						This estimate is to give you an idea of cost based on what
																						other customers paid for jobs in your category. Your actual
																						job cost is charged per minute while you're in the call on a
																						"pay-as-you-go" basis depending on your category pay rate.
																						(Software = $1.99/minute, IT = $2.49/minute).
																					</p>
																				</div>
																			</div>
																		)}
																	</span>
																</div>
															</>
														) : (
															<>
																<span className="meeting-label-name">Est. Price:</span>
																<div className="d-flex">
																	<span className="meeting-label-value">
																		<span>$</span>
																		{meetingInfo.estimatedPrice.split('-')[0]}-<span> $</span>
																		{meetingInfo.estimatedPrice.split('-')[1].trim()}
																	</span>
																	<span
																		className="estimate-info-round-div ml-1 position-relative"
																		onMouseOver={handleMouseEnter}
																		onMouseOut={handleMouseLeave}
																		role="button"
																		onTouchStart={handleMouseEnter}
																		onFocus={() => setIsHovering(true)}
																		onBlur={() => setIsHovering(false)}
																	>
																		<FontAwesomeIcon icon={faInfo} className="icon-of-i" />
																		{isHovering && (
																			<div className="estimatePriceInfo-div">
																				<div className="phoneNumberInfo-triangle"></div>
																				<div className="estimatePriceInfo-inner-div ">
																					<h6>Actual cost may be more or less.</h6>
																					<p className="estimate-price">
																						This estimate is to give you an idea of cost based on what
																						other customers paid for jobs in your category. Your actual
																						job cost is charged per minute while you're in the call on a
																						"pay-as-you-go" basis depending on your category pay rate.
																						(Software = $1.99/minute, IT = $2.49/minute).
																					</p>
																				</div>
																			</div>
																		)}
																	</span>
																</div>
															</>
														)}
													</>
												) : (
													'NA'
												)}
											</>
										)}
									</>
								)}
							</>
						) : (
							<></>
						)}
					</Col>
				</Row>
			</Col>
			<Col xs="12" className="mt-3" style={{ overflow: 'unset' }}>
				{meetingInfo && meetingInfo.customer && (
					<>
						<span className="meeting-label-name">
							{meetingInfo.updatedIssueDescription && meetingInfo.updatedIssueDescription.length > 0 ? (
								<>
									{userInfo && userInfo.userType === 'customer' && <>Issue (Added by you):</>}
									{userInfo && userInfo.userType === 'technician' && <>Issue (Added by client):</>}
								</>
							) : (
								<>Issue:</>
							)}
						</span>

						<div className={` ${addScroll ? 'isu-dec-scroll' : ''}`}>
							{addScroll
								? meetingInfo.issueDescription
								: meetingInfo.issueDescription && meetingInfo.issueDescription.length > 90
								? `${meetingInfo.issueDescription.substring(0, 150)}`
								: meetingInfo.issueDescription}
							{addScroll
								? null
								: meetingInfo.issueDescription.length > 150 && (
										<Button
											style={{ color: 'blue', textTransform: 'none' }}
											onClick={handelIssueDec}
											className="more-issue-dec"
											variant="text"
										>
											read more...
										</Button>
								  )}
						</div>
					</>
				)}
			</Col>
			{meetingInfo && meetingInfo.customer && meetingInfo.updatedIssueDescription && meetingInfo.updatedIssueDescription.length > 0 ? (
				<Col
					xs="12"
					className={`mt-3 ${
						addScrollTech &&
						meetingInfo.updatedIssueDescription[meetingInfo.updatedIssueDescription.length - 1].issueDescription.length > 150
							? ''
							: ''
					}`}
				>
					<span className="meeting-label-name">
						Issue [Updated by technician (
						{meetingInfo.updatedIssueDescription[meetingInfo.updatedIssueDescription.length - 1].technicianName}
						)]:
					</span>
					<div className="issue-description">
						{addScrollTech
							? meetingInfo.updatedIssueDescription[meetingInfo.updatedIssueDescription.length - 1].issueDescription
							: meetingInfo.updatedIssueDescription[meetingInfo.updatedIssueDescription.length - 1].issueDescription &&
							  meetingInfo.updatedIssueDescription[meetingInfo.updatedIssueDescription.length - 1].issueDescription.length > 90
							? `${meetingInfo.updatedIssueDescription[meetingInfo.updatedIssueDescription.length - 1].issueDescription.substring(
									0,
									150,
							  )}`
							: meetingInfo.updatedIssueDescription[meetingInfo.updatedIssueDescription.length - 1].issueDescription}
						{!addScrollTech &&
							meetingInfo.updatedIssueDescription[meetingInfo.updatedIssueDescription.length - 1].issueDescription.length > 150 && (
								<Button
									style={{ color: 'blue', textTransform: 'none' }}
									onClick={handelIssueDecTech}
									className="more-issue-dec"
									variant="text"
								>
									read more...
								</Button>
							)}
					</div>
				</Col>
			) : null}
		</Row>
	)
}

// const EstimatedPriceToggle = (props) => {
// 	const time1 = props.softwareSettings ? parseInt(props.softwareSettings.estimatedTime.split('-')[0]) : 'NA'
// 	const time2 = props.softwareSettings ? parseInt(props.softwareSettings.estimatedTime.split('-')[1]) : 'NA'
// 	const price_per_six_min = props.softwareSettings.rate
// 	const price1 = props.softwareSettings ? props.softwareSettings.estimatedPrice.split('-')[0] : price_per_six_min
// 	const price2 = props.softwareSettings ? props.softwareSettings.estimatedPrice.split('-')[1] : price_per_six_min
// 	let temp1 = (time1 / 6) * parseInt(price1)
// 	temp1 = temp1 ? temp1.toFixed(0) : 'NA'
// 	let temp2 = (time2 / 6) * parseInt(price2)
// 	temp2 = temp2 ? temp2.toFixed(0) : 'NA'
// 	return (
// 		<>
// 			${temp1}-{temp2}
// 		</>
// 	)
// }

export default MeetingInfo
